import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoWhite from "../images/Social-Minds-White-Logo.svg"
import HostCal from "../images/cal.jpg"
import HostPol from "../images/pollyanna.jpg"
import Hosteve from "../images/eve.jpg"
import Hostmike from "../images/Mike.jpg"

const AboutPage = () => (
  <Layout className="aboutpage about-hero-img">
    <div className="hero-text">
      <SEO title="About" />
      <h1>
        <img alt="logo" src={LogoWhite} width="512" />
      </h1>
      <p className="desktop">
      Social Minds puts you in a room with marketing’s brightest minds. We interview the world’s biggest brands, break the latest social media headlines and translate social-first trends for the layman. Our promise to you? No buzzwords, jargon or fluff. Just real case studies, genuine experts and actionable insights. That’s why we’re the world’s favourite social media marketing podcast.
      </p>
    </div>
    <section className="about">
      <h2 className="desktop">Hosted by...</h2>
      <div className="host">
        <p className="mobile about-us-text">
        Social Minds puts you in a room with marketing’s brightest minds. We interview the world’s biggest brands, break the latest social media headlines and translate social-first trends for the layman. Our promise to you? No buzzwords, jargon or fluff. Just real case studies, genuine experts and actionable insights. That’s why we’re the world’s favourite social media marketing podcast.
        </p>
        <h2 className="mobile">Hosted by...</h2>
        <div className="host__block">
          <div className="host__img">
            <img alt="Eve Young" src={Hosteve} width="150" />
          </div>
          <div className="host__name">Eve Young</div>
          <div className="host__job-title">
              Senior Copywriter, Social Chain
          </div>
          <div className="host__bio">
          Eve’s career has seen her go from building B2B brands from scratch on social with next to no budget, to now writing for brands including Arla Foods, Jameson Irish Whiskey and KFC UK with the might of Social Chain’s full service offering at her back. She’s always had a knack for predicting the next big moves in the social landscape; she is embedded in online culture. She now combines that with a deep respect for traditional advertising principles to produce relevant and effective creative brand responses on social. But whether it’s been B2B or B2C, corporate or creative, Eve’s passion has remained helping brands to recognise and implement the power of social - something she has done through this podcast for the last four years.          </div>
        </div>
        <div className="host__block">
          <div className="host__img">
            <img alt="Calum MacLeod" src={HostCal} width="150" />
          </div>
          <div className="host__name">Calum MacLeod</div>
          <div className="host__job-title">
              Head of Creative Shop, Social Chain
          </div>
          <div className="host__bio">
          With over seven years experience in social media marketing, Calum knows what it takes for brands to succeed on social. In his career to date, he's worked alongside some of the world’s biggest brands, including The FA, PUMA Football and LIV Golf. With them, Calum has masterminded multiple viral, creative campaigns by using his deep understanding of consumer psychology and the social landscape. He's a social native who's always on the lookout for innovative ways to implement new platform updates and features. He enjoys delving into the 'why' behind social creative and strategy, and promises to do the same for Social Minds’ listeners.          </div>
        </div>
      </div>

      <div className="host">
        <div className="host__block">
        <h2 className="desktop">Produced by...</h2>
        <h2 className="mobile">Produced by...</h2>
          <div className="host__img">
            <img alt="Mike Pickston" src={Hostmike} width="150" />
          </div>
          <div className="host__name">Mike Pickston</div>
          <div className="host__job-title">
          Producer, Social Chain
          </div>
          <div className="host__bio">
          Mike has spent the last decade producing live radio programmes for major radio stations across the UK such as Key 103, Hits Radio, Greatest Hits Radio and Radio City, having previously worked for media giant Bauer Media. His experience running his own podcast has taken him across the world producing content in Spain, New York and Las Vegas, producing content across a wide range of subjects from sport, lifestyle and health and fitness. Now, he brings to Social Minds his attention to detail in audio and his knowledge of audio trends and listener behaviour.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
